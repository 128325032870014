@use '../config/' as *;
// Mobile-first base styles
.auction-container {
  flex-direction: column-reverse;
  @extend %flex-c-c;
  .fb_iframe_widget_fluid_desktop,
  .fb_iframe_widget_fluid_desktop span,
  .fb_iframe_widget_fluid_desktop iframe {
    max-width: 100% !important;
    width: 100% !important;
  }
  .auction-comments,
  .auction-data {
    flex-basis: 100%;
    max-width: 100%;
    @extend %w-100;
  }
  .auction-comments {
    border: .1rem solid var(--neutral-600);
    @extend %pt-0;
    .waf-head {
      background: url(/static-assets/images/cssimages/placeholder-bg.png) var(--secondary-800);
      background-size: cover;
      background-position: 100% 145%;
      @extend %p-2;
      .title {
        @extend %neutral-50;
        @extend %font-20;
      }
    }
  }
  .auction-data {
    @extend %p-0
  }
}
.waf-auction {
  padding: 0 0 var(--space-3);
  .overseas-player {
    position: absolute;
    right: -40px;
    top: 50%;
    transform: translateY(-50%);
    width: 2.5rem;
    height: 2.5rem;
    line-height: 2.5rem;
    @extend %text-center;
    @extend %primary-500;
    @extend %font-8;
    @extend %circle-radius;
    &:before {
      @include icon(flight, 14);
    }
  }
  .role {
    position: absolute;
    left: -6px;
    top: 50%;
    transform: translateY(-43%);
    width: 3.6rem;
    height: 3.6rem;
    line-height: 3.6rem;
    @extend %secondary-800;
    @extend %text-center;
    @extend %font-36-pm;
    &.bowler {
      @extend %font-30;
      &:before {
        @include icon(bowler, null);
      }
    }
    &.batsman {
      &:before {
        @include icon(batter, null);
      }
    }
    &.wicket-keeper {
      &:before {
        @include icon(wicket-keeper, null);
      }
    }
    &.all-rounder {
      &:before {
        // all-rounder
        @include icon(all-rounder, null);
      }
    }
  }
  .player-info {
    background: url(/static-assets/images/cssimages/auctionbg.png) var(--neutral-50) no-repeat;
    background-size: cover;
    @extend %relative;
    @extend %p-3;
    .detail-wrapper {
      flex-direction: column;
      @extend %flex-c-c;
      .left-wrapper,
      .right-wrapper {
        flex-basis: 100%;
        max-width: 100%;
      }
      .right-wrapper {
        flex-shrink: 0;
        @extend %w-90;
        @extend %m-4-0;
        @extend %text-center;
      }
      .price {
        @extend %relative;
        &:before {
          content: "";
          width: 20rem;
          height: .1rem;
          border-top: .1rem solid clr(secondary-1100, 4);
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          @extend %mx-auto
        }
      }
    }
    .player-name {
      line-height: 1;
      @extend %uppercase;
      @extend %secondary-1100;
      .name {
        line-height: 2.4rem;
        @extend %font-24-pb;
      }
      .designation {
        @extend %font-14;
      }
    }
    .player-role {
      line-height: 3.1rem;
      @extend %font-18;
      @extend %pl-0;
      @extend %neutral-900;
      .overseas-player {
        right: 0;
        display: inline-block;
        top: unset;
        transform: unset;
        @extend %relative;
      }
    }
    .price {
      @extend %neutral-700;
      @extend %pt-2;
      @extend %font-18-pm;
      @extend %mt-2;
      .currency {
        vertical-align: middle;
        @extend %font-34-pb;
      }
      .txt-3 {
        display: block;
        @extend %secondary-1100;
        @extend %font-24-pb;
      }
    }
    .status {
      height: 15rem;
      text-align: center;
      span {
        height: 15rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        &.sold {
          &:before {
            content: "\e919";
            @include icon(sold, 120);
            @extend %auction-sold;
          }
        }
        &.unsold {
          &:before {
            @include icon(unsold, 150);
            @extend %auction-unsold;
          }
        }
        &.rtm {
          &:before {
            @include icon(rtm, 120);
            @extend %auction-rtm;
          }
        }
        &.current {
          &:before {
            @include icon(auction, 120);
            @extend %auction-auction;
          }
        }
      }
    }
  }
  .tab-section {
    @extend %p-1-0;
  }
  .tabs.auction-tabs {
    border: .1rem solid var(--primary-500);
    @extend %half-radius;
    @extend %p-0;
    ul {
      li {
        flex: 1;
        padding: .7rem;
        cursor: pointer;
        @extend %text-center;
        @extend %primary-500;
        &.active {
          @extend %primary-500-bg;
          @extend %secondary-700;
        }
      }
    }
  }
  // Team slider section
  .team {
    &-slider {
      @extend %p-0-4;
      @extend %relative;
      @extend %neutral-50-bg;
      .slide-nav {
        &:before {
          @extend %font-24;
        }
      }
    }
    &-item {
      width: 8rem;
      cursor: pointer;
      @extend %text-center;
      @extend %p-2;
      &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 0;
        height: .4rem;
        transform: translateX(-50%);
        transition: 300ms width;
        @extend %secondary-800-bg;
        @extend %hidden;
        @extend %half-radius;
      }
      &:hover,
      &.active {
        &:before {
          @extend %w-100;
        }
        &:after {
          content: "";
          position: absolute;
          border-bottom: .8rem solid var(--secondary-800);
          border-left: .8rem solid transparent;
          border-right: .8rem solid transparent;
          bottom: -1rem;
          transform: translate(-50%, -50%) rotate(180deg);
          left: 50%;
        }
      }
    }
    &-wrap {
      @extend %relative;
      @extend %p-2-0;
    }
    &-photo {
      @extend %text-center;
      img {
        @extend %w-80;
        @extend %mx-auto
      }
    }
    &-name {
      display: none;
      top: var(--space-1);
      @extend %font-14;
      @extend %neutral-400;
      @extend %relative;
    }
    &-info {
      @extend %m-5-0;
      @extend %full-radius;
      .info-listing {
        border: .1rem solid var(--neutral-50);
        flex-wrap: wrap;
        @extend %flex-c-fs;
        @extend %p-0
      }
      .info-item {
        flex: 1 0 50%;
        @extend %text-center;
        @extend %relative;
        @extend %py-4;
        // &:nth-child(odd):after {
        //   content: "";
        //   height: 70%;
        //   width: .1rem;
        //   right: 0;
        //   @extend %neutral-100-bg;
        //   @extend %d-block;
        //   @extend %pos-y-center;
        // }
        // &:nth-child(3):after {
        //   @extend %neutral-50-bg;
        // }
        &:nth-child(3),
        &:nth-child(4) {
          @extend %primary-500-bg;
        }
        &:nth-last-child(-n+2) {
          margin-bottom: 0;
        }
        .txt-1 {
          @extend %font-13;
          @extend %uppercase;
        }
        .txt-3 {
          display: block;
          @extend %font-24-pb;
          @extend %secondary-800;
        }
        .tooltip {
          @extend %d-inline-block;
          .info-icon {
            cursor: pointer;
            @extend %relative;
            @extend %font-0;
            &:before {
              @include icon(info, 14);
              @extend %d-inline-block;
            }
            &:after {
              content: "";
              transform: rotate(45deg);
              opacity: 0;
              visibility: hidden;
              @include position(null, null, 2rem, 0.2rem);
              @include square(1rem);
              @extend %transition;
              @extend %neutral-50-bg;
            }
            &:hover {
              &:after,
              + .tooltip-data {
                opacity: 1;
                visibility: visible;
              }
            }
          }
          .tooltip-data {
            width: 26rem;
            box-shadow: 0 0 1rem 0 clr(neutral-1000, 2);
            z-index: var(--z-overlay);
            opacity: 0;
            top: -5.2rem;
            visibility: hidden;
            translate: -50% 0;
            @extend %absolute;
            @extend %transition;
            @extend %p-2;
            @extend %neutral-50-bg;
          }
        }
      }
    }
  }
  .info-listing {
    @extend %neutral-50-bg
  }
  .players-section {
    .mod-head {
      border-bottom: none;
      @extend %secondary-900-bg;
      @extend %mb-0;
      .title {
        @extend %neutral-50;
        @extend %font-14;
        @extend %p-2;
        @extend %uppercase;
        @extend %m-0;
      }
    }
    .players-section-list {
      @extend %mb-5;
    }
  }
  .player-listing.table {
    .t-body {
      .tr {
        &:nth-child(odd) {
          @extend %neutral-50-bg;
        }
      }
    }
    .tr {
      border-bottom: .1rem solid var(--neutral-50);
      @extend %flex-sb-c;
      &:last-child {
        border-bottom: none;
      }
      &.header {
        .td {
          @extend %font-13;
        }
      }
    }
    .player-detail {
      @extend %relative;
    }
    .td {
      flex-basis: 20%;
      line-height: 1;
      @extend %font-14;
      @extend %p-2-1;
      @extend %text-center;
      &.player {
        flex-basis: 40%;
        @extend %text-left;
        @extend %flex-n-c;
      }
      &.base-price,
      &.selling-price {
        @extend %text-right;
        @extend %font-12;
        @extend %neutral-600;
      }
      &.base-price {
        @extend %p-2;
      }
      .name {
        display: block;
        top: 0;
        @extend %relative;
        @extend %pl-6;
        @extend %uppercase;
        @extend %font-14;
      }
      .player-photo {
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
        background-size: cover;
        background-position: center;
        @extend %hidden;
        @extend %mr-2;
      }
      .role {
        width: 1.8rem;
        height: 1.8rem;
        line-height: 1.8rem;
        left: 0;
        @extend %font-16;
      }
    }
  }
  // Updates section
  .updates-list {
    .updates-item {
      @extend %neutral-50-bg;
      @extend %mb-3;
      @extend %p-2;
      @extend %half-radius;
      @extend %relative;
    }
    .update-title {
      @extend %neutral-800;
      @extend %font-20;
    }
    .time {
      @extend %neutral-800;
      @extend %font-14;
      position: absolute;
      bottom: .5rem;
      right: 1rem;
    }
    .img-section {
      img {
        height: 25rem;
        width: auto;
      }
      .img-area {
        @extend %flex-c-n;
        @extend %m-3-0;
      }
    }
    .twitter-section {
      @extend %flex-c-n;
      @extend %m-3-0;
    }
  }
  .team-slider {
    @extend %px-5
  }
  .swiper-button-next,
  .swiper-button-prev {
    width: 2.5rem;
  }
  .swiper-button-prev,
  .swiper-button-next {
    transform: translateY(-50%);
  }
}
// Tablet and larger screens
@include mq(col-md) {
  .auction-container {
    flex-direction: row;
    .auction-comments {
      flex-basis: calc(30% - var(--space-2));
      max-width: calc(30% - var(--space-2));
    }
    .auction-data {
      flex-basis: calc(70% - var(--space-2));
      max-width: calc(70% - var(--space-2));
    }
  }
  .auction-wrapper {
    max-width: var(--container-max-width);
    margin: auto;
  }
  .waf-auction {
    padding: 0 0 var(--space-5);
    .player-info {
      padding: var(--space-4);
      .detail-wrapper {
        flex-direction: row;
        .left-wrapper {
          flex-basis: 40%;
          max-width: 40%;
        }
        .price:before {
          margin-left: 0;
        }
        .right-wrapper {
          flex-basis: 60%;
          max-width: 60%;
          margin: 0;
          text-align: left;
        }
      }
      .player-name {
        .name {
          font-size: 3rem;
          line-height: 3.6rem;
        }
        .designation {
          font-size: 2.4rem;
        }
      }
      .player-role {
        font-size: 2.2rem;
      }
      .price {
        font-size: 2.2rem;
        .txt-3 {
          font-size: 3rem;
          line-height: 3.6rem;
        }
      }
    }
    .tab-section {
      padding: var(--space-4) 0;
    }
    .tabs.auction-tabs {
      ul {
        li {
          padding: var(--space-2);
        }
      }
    }
    .team-info {
      .info-item {
        flex-basis: 25%;
        margin-bottom: 0;
        .txt-1 {
          font-size: 1.6rem;
        }
        .txt-3 {
          font-size: 3rem;
        }
        .tooltip {
          .tooltip-data {
            translate: unset;
            right: 1rem;
            left: auto;
            &:after {
              right: 3.4rem;
            }
          }
        }
      }
    }
    .players-section {
      .mod-head {
        .title {
          font-size: 2.2rem;
        }
      }
    }
    .player-listing.table {
      .tr {
        &.header {
          .td {
            font-size: 1.8rem;
          }
        }
      }
      .td {
        font-size: 1.8rem;
        padding: 1.8rem 2rem;
        .name {
          font-size: 1.8rem;
          padding-left: 4rem;
        }
        .role {
          width: 3rem;
          height: 3rem;
          font-size: 2.8rem;
        }
        .player-photo {
          width: 7rem;
          height: 7rem;
          margin-right: 1.5rem;
        }
        &.base-price,
        &.selling-price {
          font-size: 2.4rem;
        }
      }
    }
    .slider {
      .slider-navigation {
        .slide-nav {
          display: none;
        }
      }
    }
  }
}
// Desktop screens
@include mq(col-lg) {
  .waf-auction {
    .swiper-button-next,
    .swiper-button-prev {
      display: none;
    }
    .player-info {
      .status {
        span {
          &.sold,
          &.rtm {
            &:before {
              font-size: 15rem;
            }
          }
          &.current {
            &::before {
              font-size: 14rem;
            }
          }
        }
      }
    }
    .team-slider {
      .team-photo img {
        width: 100%;
      }
    }
    .team-info {
      .info-item {
        .tooltip {
          .tooltip-data {
            left: 50%;
            translate: -50% 0;
            right: 1rem;
            &:after {
              right: 5.4rem;
            }
          }
        }
      }
    }
  }
}